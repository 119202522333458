<template>
  <div>
    <div
      id="basic-non-sticky-notification-content"
      class="toastify-content hidden flex flex-col sm:flex-row"
    >
      <div class="font-medium" v-if="pagedata.message">
        {{ pagedata.message }}
      </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">New Process</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-8">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="crud-form-1" class="form-label">Process Name</label>
                <input
                  id="crud-form-1"
                  type="text"
                  v-model="process.process_name"
                  class="form-control w-full"
                  placeholder="Process Name"
                />
                <span class="text-primary-3" v-if="errors.process_name">{{
                  errors.process_name[0]
                }}</span>
              </div>
              <div>
                <label for="crud-form-1" class="form-label">Process Code</label>
                <input
                  id="crud-form-1"
                  type="text"
                  v-model="process.process_code"
                  class="form-control w-full"
                  placeholder="Process Code"
                />
                <span class="text-primary-3" v-if="errors.process_code">{{
                  errors.process_code[0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="crud-form-2" class="form-label">Labour Type</label>
                <TomSelect
                  v-model="process.labour"
                  id="crud-form-2"
                  :options="{
                    placeholder: 'Select your Labour'
                  }"
                  class="w-full"
                >
                  <option :value="1">Single Time</option>
                  <option :value="2">Multiple Time</option>
                </TomSelect>
              </div>
              <div>
                <label for="crud-form-2" class="form-label">Process Type</label>
                <TomSelect
                  v-model="process.type"
                  @change="processTypeUpdate"
                  id="crud-form-2"
                  :options="{
                    placeholder: 'Select your Labour'
                  }"
                  :disabled="process.id != ''"
                  class="w-full"
                >
                  <option value="rough">Rough Lot</option>
                  <option value="bunch">Rough Bunch</option>
                  <option value="makeable">Makeable</option>
                </TomSelect>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Transaction Type && Short Code</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <TomSelect
                  v-model="process.transaction_type"
                  id="crud-form-2"
                  :options="{
                    placeholder: 'Select Type'
                  }"
                  class="w-full"
                  :disabled="process.id != ''"
                >
                  <option
                    v-for="(item, index) in pagedata.process_type_filter"
                    :key="index"
                    :value="item.value"
                    >{{ item.type }}</option
                  >
                </TomSelect>
                <div class="text-primary-3" v-if="errors.transaction_type">
                  {{ errors.transaction_type[0] }}
                </div>
              </div>
              <div class="mt-2 sm:mt-0">
                <div class="input-group">
                  <div
                    id="input-group-4"
                    class="input-group-text whitespace-nowrap"
                  >
                    Short Code
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="process.short_code"
                    placeholder="Short Code"
                    aria-describedby="input-group-4"
                  />
                </div>
                <div class="text-primary-3" v-if="errors.short_code">
                  {{ errors.short_code[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Limit & Durtion</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <div class="input-group">
                  <div id="input-group-3" class="input-group-text">limit</div>
                  <input
                    type="number"
                    v-model="process.limit"
                    class="form-control"
                    placeholder="Limit Issue"
                    aria-describedby="input-group-3"
                  />
                </div>
                <div class="text-primary-3" v-if="errors.limit">
                  {{ errors.limit[0] }}
                </div>
              </div>
              <div class="mt-2 sm:mt-0">
                <div class="input-group">
                  <div id="input-group-4" class="input-group-text">Days</div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="process.duration"
                    placeholder="Time Duration"
                    aria-describedby="input-group-4"
                  />
                </div>
                <div class="text-primary-3" v-if="errors.duration">
                  {{ errors.duration[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <div>Mix Packet</div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="process.mix_stock"
                    class="form-check-switch"
                  />

                  <b class="ml-3 text-red">Note: </b>
                  <p class="ml-3" style="letter-spacing: 1px;">
                    Only makeable type packets can be mixed during the receive !
                  </p>
                </div>
              </div>
              <div class="mt-2 sm:mt-0">
                <div>Multiple Assigne</div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="process.multiple_assigne"
                    class="form-check-switch"
                  />

                  <b class="ml-3 text-red">Note: </b>
                  <p class="ml-3" style="letter-spacing: 1px;">
                    Only makeable type packets can be mixed during the receive !
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link
              :to="{ name: 'process' }"
              type="button"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </router-link>
            <button
              type="button"
              @click="CreateProcess"
              :disabled="pagedata.submit"
              class="btn btn-primary w-24"
            >
              Save
              <LoadingIcon
                v-if="pagedata.submit"
                icon="oval"
                color="white"
                class="w-4 h-4 ml-2"
              />
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, toRef, computed } from 'vue'
import Toastify from 'toastify-js'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const process = reactive({
      id: '',
      process_name: '',
      process_code: '',
      process_order: '',
      mix_stock: false,
      multiple_assigne: false,
      short_code: '',
      limit: null,
      duration: null,
      labour: 1,
      internal_bonus: false,
      external_bonus: false,
      type: 'rough',
      internal_count: 1,
      external_count: 1,
      transaction_type: 1,
      internal_bonus_rate: [''],
      external_bonus_rate: ''
    })
    const pagedata = reactive({
      errors: [],
      submit: false,
      message: '',
      internal: false,
      external: false,
      process_types: {
        rough: [1, 2, 3, 4, 5, 9, 11],
        bunch: [1],
        makeable: [1, 6, 7, 8, 10]
      },
      process_type_list: [
        { value: 1, type: 'Normal' },
        { value: 2, type: 'Recut (Ls)' },
        { value: 3, type: 'Planning' },
        { value: 4, type: 'Checking' },
        { value: 9, type: 'Rough To Makeable' },
        { value: 5, type: 'Rough To Polish (Part)' },
        { value: 6, type: 'HPHT' },
        { value: 7, type: 'LAB' },
        { value: 8, type: 'Final Polish' },
        { value: 10, type: 'Makeable To Polish' },
        { value: 11, type: 'Rough To Polish (Full)' }
      ],
      process_type_filter: []
    })
    const errors = toRef(pagedata, 'errors')
    const route = useRoute()
    const process_type_filter = ref([])

    const CreateProcess = () => {
      pagedata.submit = true

      const endpoint = route.params.id ? 'update_process' : 'add_new_process'
      const url = `api/master/${endpoint}`
      const successHandler = res => {
        pagedata.submit = false

        if (res.data.message) {
          handleMessage(res.data.message)
          router.push({ name: 'process' })
        } else if (res.data.error) {
          handleMessage(res.data.error)
        }
      }

      const errorHandler = err => {
        pagedata.submit = false
        pagedata.errors = err.response?.data?.errors || [
          'An unexpected error occurred'
        ]
        setTimeout(() => {
          pagedata.errors = []
        }, 3000)
      }

      const handleMessage = message => {
        pagedata.message = message
        setTimeout(() => {
          this.basicNonStickyNotificationToggle()
        }, 100)
        setTimeout(() => {
          pagedata.message = ''
        }, 3000)
      }

      axios({
        url,
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(successHandler)
        .catch(errorHandler)
    }

    const basicNonStickyNotificationToggle = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const getProcess = id => {
      axios({
        url: 'api/master/get_single_process_details',
        method: 'post',
        baseURL: BASE_URL,
        data: { id },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          const item = res.data.item

          // Update process data
          process.process_name = item.process_name || ''
          process.process_code = item.process_code || ''
          process.process_order = item.process_order || ''
          process.short_code = item.process_short_code || ''
          process.limit = item.mc || 0
          process.duration = item.duration || ''
          process.labour = item.labour || 1
          process.external_bonus_rate = item.external_bonus_rates || 0
          process.type = item.process_type || ''
          process.mix_stock = item.mix_stock === 1
          process.multiple_assigne = item.multiple_assigne === 1
          process.internal_bonus = item.internal_bonus === 1
          process.external_bonus = item.external_bonus === 1

          // Handle internal bonus
          if (process.internal_bonus) {
            pagedata.internal = true
            process.internal_count = item.internal_bonus_rates?.length || 1
            process.internal_bonus_rate = item.internal_bonus_rates || []
          } else {
            process.internal_count = 1
            process.internal_bonus_rate = ['']
          }

          // Transaction type
          process.transaction_type = item.transaction_type

          // Optional UI updates
          pagedata.internal = process.internal_bonus
          pagedata.external = process.external_bonus
        })
        .catch(err => {
          pagedata.errors = err.response?.data?.errors || [
            'An unexpected error occurred'
          ]
        })
    }

    const processTypeUpdate = () => {
      pagedata.process_type_filter = pagedata.process_type_list.filter(item =>
        pagedata.process_types[process.type].includes(item.value)
      )
      if (
        !pagedata.process_types[process.type].includes(
          process.transaction_type
        )
      ) {
        process.transaction_type = 1
      }
    }

    // On Mounted
    onMounted(() => {
      pagedata.process_type_filter = pagedata.process_type_list.filter(item =>
        pagedata.process_types[process.type].includes(item.value)
      );
      if (route.params.id) {
        process.id = route.params.id
        getProcess(route.params.id)
      }
    })

    return {
      pagedata,
      errors,
      CreateProcess,
      process,
      processTypeUpdate,
      process_type_filter,
      basicNonStickyNotificationToggle
    }
  }
})
</script>
