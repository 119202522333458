<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Job Done / Repairing</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 mt-2">
            <div class="mt-2">
                <label for="update-profile-form-6" class="form-label">Select Type</label>
                <div v-if="form_data.stock_details.length > 0">

                  <TomSelect v-model="form_data.transaction_type" :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Type'
                      }
                    }
                  }" class="w-full" disabled>
                          <option value="1">Job Done</option>
                          <option value="2">Repairing</option>
                      </TomSelect>
                </div>
                <div v-else>

                  <TomSelect v-model="form_data.transaction_type" :options="{
                      placeholder: 'Select Type',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Type'
                        }
                      }
                    }" class="w-full">
                      <option value="1">Job Done</option>
                      <option value="2">Repairing</option>
                  </TomSelect>
                </div>
            </div>
        </div>
        <div class="col-span-6 mt-2">
            <div class="mt-2">
                <label for="update-profile-form-6" class="form-label">Select Party</label>

                <div v-if="form_data.stock_details.length > 0">
                  <TomSelect v-model="form_data.party_id" :options="{
                      placeholder: 'Select Party',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Party'
                        }
                      }
                    }" class="w-full" disabled>
                      <option value="0">Select Party</option>
                      <option v-for="item in page_data.vendor_parties" :key="item.id" :value="item.id">{{item.first_name}} {{ item.last_name }}</option>
                  </TomSelect>
                </div>
                <div v-else>
                  <TomSelect v-model="form_data.party_id" :options="{
                      placeholder: 'Select Party',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Select Party'
                        }
                      }
                    }" class="w-full">
                      <option value="0">Select Party</option>
                      <option v-for="item in page_data.vendor_parties" :key="item.id" :value="item.id">{{item.first_name}} {{ item.last_name }}</option>
                  </TomSelect>
                </div>
            </div>
        </div>
        <div class="col-span-6 mt-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Inward Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="form_data.issue_date"
            />
          </div>
        </div>
        <div class="col-span-6 mt-2">
          <label for="update-profile-form-6" class="form-label py-1 flex"
              >Scan Packets
              (
                <span class="flex items-center">
                  <label for="remember-me" class="text-md font-medium mr-2">Manual</label>
                <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                </span>
                      )</label
            >
          <div class="w-full flex items-center">
            <div class="relative text-gray-700 dark:text-gray-300 mr-4 w-full">
                <input type="text" v-model="page_data.search" @change="getPacketDetailsScan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-full pr-10 border-1" placeholder="Scan Packet" />
                <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
            </div>
            <button class="btn btn-primary shadow-md mr-2" @click="add_stock_to_list">Add</button>
          </div>
        </div>
        <div class="col-span-6 mt-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Total Packets</label
            >
            <input
              type= "number"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="form_data.total_packets"
              disabled
            />
          </div>
        </div>
        <div class="col-span-6 mt-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Total Weight</label
            >
            <input
              type= "text"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="form_data.total_weight"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <!-- Begin: Scanned Lot Table -->
    <div class="intro-y box py-3 px-3 mt-2" v-if="form_data.stock_details.length > 0">
      <div
        class=" border border-r-0 border-l-0 border-t-0 pb-10" v-if="form_data.transaction_type == 1"
      >
        <table class="table w-full border">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Pkt No</th>
              <th class="whitespace-nowrap">Stock No</th>
              <th class="whitespace-nowrap">Cr. Weight</th>
              <th class="whitespace-nowrap">Exp. Wt</th>
              <th class="whitespace-nowrap">Shape</th>
              <th class="whitespace-nowrap">Color</th>
              <th class="whitespace-nowrap">Purity</th>
              <th class="whitespace-nowrap">Cut</th>
              <th class="whitespace-nowrap">Polish</th>
              <th class="whitespace-nowrap">Symmetry</th>
              <th class="whitespace-nowrap" v-if="form_data.transaction_type == 1">Job Done</th>
              <th class="whitespace-nowrap">Stone</th>
              <!-- <th class="whitespace-nowrap">Fluorescence</th> -->
              <th class="whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(packet, index) in form_data.stock_details"
              :key="index"
              class="border hover:bg-gray-200 p-0 cursor-pointer"
            >
              <td class="whitespace-nowrap">{{ packet.number }}</td>
              <td class="whitespace-nowrap">
                {{ packet.vepari_no ? packet.vepari_no : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.cr_weight ? packet.cr_weight : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.shape ? packet.shape : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.color ? packet.color : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.purity ? packet.purity : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.cut ? packet.cut : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.polish ? packet.polish : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.symmetry ? packet.symmetry : '-' }}
              </td>
              <!-- <td class="whitespace-nowrap">{{ packet.fluerocent ? packet.fluerocent : '-' }}</td> -->
              <td class="whitespace-nowrap" v-if="form_data.transaction_type == 1">
                <div>
                  <TomSelect
                    v-model="packet.job_done"
                    :options="{
                      placeholder: 'Job Done Type',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Type'
                        }
                      }
                    }"
                    class="w-full"
                  >
                    <option value="1">Job Done</option>
                    <option value="2">Cancelled</option>
                  </TomSelect>
                </div>
              </td>

              <td class="whitespace-nowrap">
                {{ packet.stone ? packet.stone : '-' }}
              </td>
              <td class="py-2 font-medium text-center">
                <div class="flex justify-start items-center p-0 m-0">
                  <a
                    href="javascript:;"
                    @click="removeItem(index)"
                    class="btn btn-danger btn-sm mx-2"
                  >
                    <XIcon class="w-4 h-4"
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class=" border border-r-0 border-l-0 border-t-0 pb-10" v-if="form_data.transaction_type == 2"
      >
        <table class="table border-2 pt-5">

          <thead>
                <tr class="border">
                  <th>Packet No</th>
                  <th>Stock No</th>
                  <th>Stock Weight(Ct)</th>
                  <th>Polish Weight(Ct)</th>
                  <th>Shape</th>
                  <th>Color</th>
                  <th>Purity</th>
                  <th>Cut</th>
                  <th>Polish</th>
                  <th>Symm</th>
                  <th>Fluc</th>
                  <th>TN</th>
                  <th>STN</th>
                  <th></th>
                </tr>
          </thead>
          <tbody class="border">

            <tr class="" v-for="(item,index) in form_data.stock_details" :key="index">
              <td class="whitespace-nowrap border-b">{{ item.number }}</td>
              <td class="whitespace-nowrap border-b">{{ item.vepari_no }}</td>
              <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.cr_weight" class="form-control rounded-none w-28" @keypress="$h.preventInvalidInput" placeholder="Weight(Ct)" aria-describedby="input-group-3" /></td>
              <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.cr_polish_weight" class="form-control rounded-none w-28" @keypress="$h.preventInvalidInput" placeholder="Polish(Ct)" aria-describedby="input-group-3" /></td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.shape"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                  <option v-for="item in page_data.shapes" :key="item.id" :value="item.id">{{ item.shape_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.color"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.colors" :key="item.id" :value="item.id">{{ item.color_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.purity"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.purities" :key="item.id" :value="item.id">{{ item.purity_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.cut"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.cuts" :key="item.id" :value="item.id">{{ item.cut_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.polish"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.polish" :key="item.id" :value="item.id">{{ item.polish_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.symmetry"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.symmetry" :key="item.id" :value="item.id">{{ item.symmetry_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.fluerocent"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.fluerocents" :key="item.id" :value="item.id">{{ item.fluerocent_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap" style="padding: 2px;">
                <select
                  class="form-select"
                  v-model="item.tension"
                  @change="loadPlanningValues(item)"
                  aria-label="Default select example"
                >
                <option v-for="item in page_data.tensions" :key="item.id" :value="item.id">{{ item.tension_name }}</option>
                </select>
              </td>
              <td class="whitespace-nowrap border-b">{{ item.stone }}</td>
              <td class="whitespace-nowrap" style="padding: 2px;"><button type="button" class="text-theme-6 flex items-center" v-if="!item.id" tabindex="-1"  @click="removeField(item.id, index)">
                      <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                    </button></td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="flex justify-between">

        <div>
          <div class="text-left">
              </div>
        </div>
        <div>
          <div class="flex justify-end align-items-center mt-5">
            <router-link :to="{ name: 'job-done-list' }" class="btn btn-outline-secondary w-24 mr-1">
                  Cancel
              </router-link>
              <div class="text-left">
                  <button type="button" @click="TakeReturnEntry" class="btn btn-primary w-24" :disabled="page_data.submit == true">Save
                      <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Scanned Lot Table -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  toRef,
  watch
} from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
import user from '@/store/user'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const focusMe = ref()
    const swal = inject('$swal')

    const page_data = reactive({
      search: '',
      search_type: '',
      check: false,
      loading: false,
      type: '',
      addornot: false,
      parties: [],
      submit: false,
      vendor_parties: [],
      message: '',
      error: '',

      total: [],
      kapan_details: [],

      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
      labs: [],
    })
    // const success = toRef(page_data, 'success')
    const form_data = reactive({
      transaction_type: '1',
      packet_type: '',
      total_packets: 0,
      total_weight: 0,
      party_id: '0',
      issue_date: '0',
      stock_details: [],
      addlist: []
    })
    const add_stock_to_list = () => {

      if(form_data.party_id == "0") {
        swal('Warning', "Party Must Be Selected !", 'warning')
      } else {

        if (page_data.search != '') {
          if (form_data.addlist.length <= 0) {
            verify_stock()
          } else {
            for (let i = 0; i < form_data.stock_details.length; i++) {
              if (
                form_data.stock_details[i].number == page_data.search ||
                form_data.stock_details[i].barnum == page_data.search ||
                form_data.stock_details[i].vepari_no == page_data.search
              ) {
                swal('Warning', 'Scanned stock already in list', 'warning')
                page_data.search = ''
                return
              } else {
                page_data.addornot = false
              }
            }
            if (page_data.addornot == false) {
              verify_stock()
            }
          }
        }
      }
    }

    const verify_stock = () => {
      const params = {
        party_id: window.btoa(form_data.party_id),
        transaction_type: window.btoa(form_data.transaction_type),
        packet_type: window.btoa(form_data.packet_type),
        number: window.btoa(page_data.search)
      }
      axios({
        url: 'api/job-done/scan_out_packet',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
        .then(res => {
          if (res.data.status == 'error') {
            page_data.search = ''
            focusMe.value.focus()
            swal('Warning', res.data.message, 'warning')
          } else {
            const index = form_data.addlist.length
            form_data.addlist[index] = res.data.item.number
            form_data.stock_details[index] = res.data.item
            form_data.packet_type = res.data.packet_type
            calculate_total()
            page_data.search = ''
            focusMe.value.focus()
          }
        })
        .catch(err => {
          page_data.search = ''
          focusMe.value.focus()
          swal('Warning', err.response.data.error, 'warning')
        })
    }

    const removeItem = id => {
      form_data.addlist.splice(id, 1)
      form_data.stock_details.splice(id, 1)

      if (form_data.addlist.length <= 0) {
        form_data.party_id = ''
        form_data.packet_type = ''
      }
    }

    const calculate_total = () => {

      form_data.total_packets = 0;
      form_data.total_weight = 0;

      form_data.stock_details.forEach(element => {

        form_data.total_weight += parseFloat(element.cr_weight);
      });

      form_data.total_packets = form_data.stock_details.length;
      form_data.total_weight = $h.myFloat(form_data.total_weight);
    }

    const TakeReturnEntry = () => {
      page_data.submit = true
      swal({
        title: 'Are you sure?',
        text: 'You are You Want To Take Submit Records!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {

          let params = form_data

          let api_url = 'api/job-done/job_done_transfer';
          let promise = axios({
              url: api_url,
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),

              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              },
          });
          return promise
          .then((result) => result.data)
          .then((data) => {
              if (data.status == 'success') {
                  swal("Success!", data.message, "success");
                  page_data.addlist = []
                  page_data.packet_list = []
                  page_data.party_id = ''
                  page_data.submit = false
                  router.push({
              'path': '/packets/job-done/transaction'
            })
              } else {
                page_data.submit = false
                  swal("Warning!", "Somthing Wants Wrong!", "warning");
              }
          }).catch(() => {
            page_data.submit = false
          })

        }
      }).catch(() => {
        page_data.submit = false
      })
      page_data.submit = false
    }

    const getPartyDetails = () => {
      axios({
        url: 'api/master/dp_parties',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        page_data.vendor_parties = res.data.items
      })
    }

    const get_all_parameters = () => {
            axios({
                url: 'api/master/get_all_parameters',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.colors = res.data.color
                page_data.cuts = res.data.cut
                page_data.purities = res.data.purity
                page_data.polish = res.data.polish
                page_data.shapes = res.data.shape
                page_data.symmetry = res.data.symmetry
                page_data.fluerocents = res.data.fluerocent
                page_data.tensions = res.data.tension
                page_data.stones = res.data.stone
                page_data.labs = res.data.lab
            })
        }

    const getPacketDetailsScan = () => {
      if (!page_data.check && page_data.search) {
        add_stock_to_list()
      }
    }

    onMounted(() => {
      get_all_parameters()
      getPartyDetails()
      focusMe.value.focus()
    })

    return {
      page_data,
      form_data,
      focusMe,
      user_details,
      getPacketDetailsScan,
      add_stock_to_list,
      removeItem,
      getPartyDetails,
      TakeReturnEntry
    }
  }
  // ,
  // mounted() {
  //   this.$refs.focusMe.focus()
  // }
})
</script>
